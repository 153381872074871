<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('settings.changePassword') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('settings.changePasswordExplanation1') }}
					</p>
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('settings.changePasswordExplanation2') }}
					</p>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-form ref="form" v-model="valid">
						<v-text-field
							v-model="oldPassword"
							:type="showOldPassword ? 'text' : 'password'"
							:label="$t('auth.oldPassword')"
							:hint="$t('auth.oldPasswordHelp')"
							outlined
							:rules="rules.password"
							rounded
							prepend-inner-icon="mdi-lock-reset"
							hide-details="auto"
							class="pb-3"
							style="max-width: 400px"
							:append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showOldPassword = !showOldPassword"
						/>
						<v-text-field
							v-model="password"
							:type="showPassword ? 'text' : 'password'"
							:label="$t('auth.newPassword')"
							:hint="$t('auth.passwordHelp')"
							:rules="rules.password"
							required
							outlined
							rounded
							prepend-inner-icon="mdi-lock"
							hide-details="auto"
							class="pb-3"
							style="max-width: 400px"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
						/>
						<v-text-field
							v-model="passwordAgain"
							:type="showPassword ? 'text' : 'password'"
							:label="$t('auth.newPasswordAgain')"
							:rules="rules.passwordAgain"
							required
							outlined
							rounded
							prepend-inner-icon="mdi-lock"
							hide-details="auto"
							class="pb-3"
							style="max-width: 400px"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
						/>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn x-large rounded class="px-4" color="primary" @click="callChangePassword()">
				{{ $t('settings.changePassword') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsChangePasswordForm',
	data() {
		return {
			valid: false,
			showOldPassword: false,
			showPassword: false,
			oldPassword: '',
			password: '',
			passwordAgain: '',
			rules: {
				password: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.passwordMaxLength) || this.$t('rules.length', { length: this.passwordMaxLength }),
				],
				passwordAgain: [(v) => v === this.password || this.$t('rules.passwordAgainMatch')],
			},
		}
	},
	computed: {
		passwordMaxLength() {
			return 128
		},
	},
	methods: {
		callChangePassword() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changePassword({
				currentPassword: this.oldPassword,
				newPassword: this.password,
				newPasswordAgain: this.passwordAgain,
			})
		},
		...mapActions('settings', ['changePassword']),
	},
}
</script>
